@tailwind base;
@tailwind components;
@tailwind utilities;
*::-webkit-scrollbar {
  background: rgb(229 231 235);
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-thumb {
  background: rgb(49 46 129);
  border-radius: 8px;
}
